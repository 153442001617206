/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #595959;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  // p {
  // 	margin: 0 0 5px 0;
  // }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #595959;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #footer-info {
    color: #777777;
  }
}
.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header,
#sideNav {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      margin-right: 10px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 55px;
    }
    span {
      display: inline;
      font-size: 23px;
      font-weight: 600;
      color: $header-color;
      line-height: 0.9em;
      vertical-align: middle;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      // float: right;
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
    }
  }
}
#banner-wrap {
  ul {
    font-weight: 600;
  }
  &.home {
    background: transparent url(../images/home-banner-sm.jpg) no-repeat 50% 50% / cover;
    #banner {
      position: relative;
      h1 {
        line-height: 30px;
      }
      .card {
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

#sideNav {
  span.big,
  span.small {
    display: inline;
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
    line-height: 1em;
    vertical-align: middle;
  }
  span.big {
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.2;
  }
  span.small {
    display: none;
    margin-top: 0;
    font-size: 16px;
    letter-spacing: 0.03rem;
    font-family: var(--font-family);
    font-weight: 600;
  }
}
.bg-light {
  background: #f6f9fb !important;
}
.bg-alternate {
  background: #0c4460;
  background: linear-gradient(30deg, #043350, #043350 30%, #027791);
}
.practice-test {
  transition: 0.3s;
  &:hover {
    background: darken(#f6f9fb, 10%) !important;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    span {
      font-size: 28px;
    }
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
  }
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner-wrap {
    &.home {
      #banner {
        .card {
          max-width: 530px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    &.home {
      background: transparent url(../images/home-banner.jpg) no-repeat 0 0 / cover;
      #banner {
        .card {
          background: #fff;
        }
      }
    }
  }
}
